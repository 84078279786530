<template>
  <div class="container">
    <alert
      v-if="feature"
      type="info"
      message="New feature! Swap either an ingredient or an article for a specific days in the weekly menu"
      @close="feature = false"></alert>
    <edit-header
      entity="swap"
      :error.sync="error"
      :invalid.sync="invalid"
      :invalid-message="invalidMessage"
      :submitted.sync="submitted"
      :isNew="isNew"/>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="card-options">
              <a
                v-if="!edit"
                href="#"
                data-test="link-edit-filter"
                class="btn btn-link"
                @click.prevent="edit = true">Edit filter</a>
            </div>
          </div>
          <div class="card-body">
            <dimmer :active="searching">
              <swap-filters
                ref="filter"
                :edit.sync="edit"
                :filter.sync="filter"
                @valid="data => applyFilter(data)"/>
            </dimmer>
          </div>
        </div>
      </div>
    </div>
    <form
      v-if="!edit"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">
        <div class="col">
          <div data-test="card-swap" class="card">
            <div class="card-header">
              <div class="card-title">
                Swap {{filter.swapType}}
              </div>
            </div>
            <div class="table-responsive">
              <MasterSwapTable
                v-if="isMasterIngredient"
                :items-list="item"
                :to="to"
                :nameFrom="filter.ingredientFrom.name"
                @master-swap-changed="handleMasterSwapChanges"/>
              <table
                v-else
                data-test="table-swap"
                class="table table-outline table-vcenter card-table">
                <thead>
                  <tr>
                    <th data-test="thead-count" colspan="2">Showing {{item.length}} recipes to swap</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="recipe in item"
                    :key="recipe.id"
                    :class="{ 'table-active': recipe.skipRecipeSwap }">
                    <td class="recipe-name-column">
                      <div class="d-flex flex-column justify-content-between">
                        <router-link
                          :to="`/recipes/${recipe.id}`"
                          target="_blank"
                          data-test="link-recipe"
                          rel="noopener noreferrer">
                          {{recipe.name}}
                        </router-link>
                        <div class="mt-4">
                          <label data-test="label-skip-swap" class="form-check form-check-inline">
                            <input
                              v-model="recipe.skipRecipeSwap"
                              class="form-check-input"
                              type="checkbox">
                            <span class="form-check-label small">Skip swap for this recipe</span>
                          </label>
                        </div>
                      </div>
                    </td>
                    <td v-if="recipe.skipRecipeSwap"></td>
                    <td v-else>
                      <div class="d-flex align-items-center">
                        <div data-test="card-from" class="card bg-light p-2">
                          <div class="card-header px-0 mx-2">
                            <div class="card-title">
                              <h4 class="mb-0">FROM:</h4>
                              <h3>{{from.name}}</h3>
                            </div>
                          </div>
                          <div class="card-body px-0">
                            <table class="table bg-transparent table-sm custom-table">
                              <thead>
                                <tr>
                                  <th class="border-0"></th>
                                  <th
                                    v-for="(ingredient, people) in recipe.from.people"
                                    :key="ingredient.id"
                                    class="border-bottom-0 text-center">
                                    {{people}} People
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(label, row) in ['Quantity', 'Portion']" :key="row">
                                  <th class="pl-2">{{label}}</th>
                                  <td
                                    v-for="ingredient in recipe.from.people"
                                    :key="ingredient.id"
                                    class="text-center">
                                    <input
                                      v-if="!row"
                                      type="text"
                                      class="form-control"
                                      disabled
                                      :value="ingredient.quantity">
                                    <input
                                      v-else
                                      type="text"
                                      class="form-control"
                                      disabled
                                      :value="`${ingredient.portion_size} ${(ingredient.portion_size_uom || {}).name}`">
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div>
                          <h1 class="fe fe-chevrons-right big-chevron"></h1>
                        </div>
                        <div data-test="card-to" class="card border-success card-to p-2">
                          <div class="card-header px-0 mx-2">
                            <div class="card-title">
                              <h4 class="mb-0">TO:</h4>
                              <h3>{{to.name}}</h3>
                            </div>
                          </div>
                          <div class="card-body px-0">
                            <table data-test="table-people" class="table bg-transparent table-sm custom-table">
                              <thead>
                                <tr>
                                  <th class="border-0"></th>
                                  <th
                                    v-for="(ingredient, people) in recipe.from.people"
                                    :key="ingredient.id"
                                    class="border-bottom-0 text-center">
                                    {{people}} People
                                    <div v-if="Object.keys(recipe.from.people).length > 1 && !isIngredient">
                                      <label class="form-check form-check-inline">
                                        <input
                                          v-model="recipe.to[people].skipArticle"
                                          data-test="input-skip-swap"
                                          class="form-check-input"
                                          type="checkbox">
                                        <span class="form-check-label small">Skip swap</span>
                                      </label>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(label, row) in ['Quantity', 'Portion']" :key="row">
                                  <th class="pl-2">{{label}}</th>
                                  <template v-for="(ingredient, people) in recipe.from.people">
                                    <td
                                      v-if="recipe.to[people].skipArticle && !row"
                                      :key="ingredient.id"
                                      rowspan="2"
                                      class="text-center text-nowrap font-weight-bold">
                                      Skipped
                                    </td>
                                    <td
                                      v-else-if="!recipe.to[people].skipArticle"
                                      :key="ingredient.id"
                                      class="text-center text-nowrap">
                                      <select
                                        v-if="!row"
                                        v-model="recipe.to[people].quantity"
                                        data-test="select-to-quantity"
                                        class="form-control custom-select"
                                        required>
                                        <option :value="null">Select</option>
                                        <option
                                          v-for="range in 10"
                                          :key="range"
                                          :value="range">
                                          {{range}}
                                        </option>
                                      </select>
                                      <select
                                        v-else
                                        data-test="select-to-portion"
                                        class="form-control custom-select"
                                        :value="(recipe.to[people].article || {}).id"
                                        :disabled="!isIngredient"
                                        :required="isIngredient"
                                        @input="changeToArticle($event.target.value, recipe.to[people])">
                                        <option :value="null">Select</option>
                                        <option
                                          v-for="article in to.articlesForRecipes"
                                          :key="article.id"
                                          :value="article.id">
                                          {{article.portion_size}} {{(article.portion_size_uom || {}).name}}
                                        </option>
                                      </select>
                                    </td>
                                  </template>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="!item.length">
                    <td class="text-center" colspan="2">
                      No recipes to swap
                      <div><button class="btn btn-sm btn-primary" @click.prevent="edit = true">Edit filter</button></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :canCreate="canCreate()"
            :dirty="dirty"
            entity="swap"
            :isNew="true"
            :submitting="submitting">
          </edit-footer>
        </div>

      </div>
    </form>
  </div>
</template>

<script>

import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import SwapFilters from './SwapFilters';
import MasterSwapTable from './MasterSwapTable';
import edit from '@/mixins/edit';
import {swaps, swappable, swappableInMaster, swapsMasterIngredient} from '@/services';
import Dimmer from '@/components/Dimmer.vue';
import Permissions from '@hellochef/shared-js/enums/Permissions';

export default {
  components: {
    Dimmer,
    EditFooter,
    EditHeader,
    SwapFilters,
    MasterSwapTable,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      edit: true,
      feature: true,
      filter: {
        articleFrom: null,
        articleTo: null,
        days: [],
        ingredientFrom: null,
        ingredientTo: null,
        swapType: '',
        weeklyMenu: null,
      },
      item: [],
      searching: false,
      masterSwapChanges: [],
    };
  },
  computed: {
    from() {
      return this.isIngredient ? this.filter?.ingredientFrom || {} : this.filter?.articleFrom || {};
    },
    isIngredient() {
      return this.filter.swapType === 'ingredient';
    },
    isMasterIngredient() {
      return this.filter.swapType === 'master-ingredient';
    },
    to() {
      if (this.isIngredient || this.isMasterIngredient) {
        return this.filter?.ingredientTo || {};
      }
      return Object.assign({}, this.filter?.articleTo, {articlesForRecipes: (this.filter?.ingredientFrom || {}).articlesForRecipes || []}) || {};
    },
  },
  methods: {
    async applyFilter() {
      this.searching = true;
      if (this.isMasterIngredient) {
        const params = {with: 'netWeightUom'};
        const {item} = await swappableInMaster.getByIdWithoutEntity(this.filter.ingredientFrom?.id, {params});
        this.searching = false;
        this.edit = false;
        this.item = item.map(it => ({
          ...it,
          to: {
            quantity: null,
            portion_size: null,
          },
        }));
        return;
      }

      const filterPayload = {
        delivery_day: this.filter.days.map(day => day.id),
        ingredient: this.filter.ingredientFrom?.id,
        weekly_menu: this.filter.weeklyMenu.id,
      };

      if (this.filter.swapType === 'article' && this.filter.articleFrom) { filterPayload.article = this.filter.articleFrom?.id; }

      const result = await swappable.getByParameters(filterPayload);

      this.searching = false;
      this.edit = false;

      // transform results for ingredients / articles
      result.items = result.items.filter(item => {
        // sometime it does have the ingredient or article but it doesn't have the same weight that I'm looking for
        let filteredIngredient = item.ingredients.filter(ingredient => ingredient.id === this.filter.ingredientFrom?.id);

        if (this.filter.swapType === 'article') {
          // grab article that have the same weight
          filteredIngredient = filteredIngredient.filter(ingredient => ingredient.portion_size === this.from.portion_size);
        }

        return filteredIngredient.length;
      }).map(item => {
        let filteredIngredient = item.ingredients.filter(ingredient => ingredient.id === this.filter.ingredientFrom?.id);

        if (this.filter.swapType === 'article') {
          // grab people that have the same weight
          filteredIngredient = filteredIngredient.filter(ingredient => ingredient.portion_size === this.from.portion_size);
        }

        const mergedIngredients = filteredIngredient.reduce((newObject, current) => {
          if (!newObject[`${current.id}-${current.index}`]) {
            newObject[`${current.id}-${current.index}`] = {

              group: current.group,
              id: current.id,
              index: current.index,
              name: current.name,
            };
          }

          const peopleTo = {
            article: null,
            quantity: null,
            skipArticle: false,
          };

          if (this.filter.swapType === 'article') {
            Object.assign(peopleTo, {

              article: this.to,
            });
          }

          // set people
          Object.assign(newObject[`${current.id}-${current.index}`], {
            people: Object.assign({}, newObject[`${current.id}-${current.index}`]?.people,
              {
                [current.people]: {

                  people: current.people,
                  portion_size: current.portion_size,
                  portion_size_uom: current.portion_size_uom,
                  quantity: current.quantity,
                },
              }),
            peopleTo: Object.assign({}, newObject[`${current.id}-${current.index}`]?.peopleTo, {

              [current.people]: peopleTo,
            }),
          });

          return newObject;
        }, {});

        item.from = Object.values(mergedIngredients)[0] || {};
        item.to = Object.values(mergedIngredients)[0]?.peopleTo || {};

        return item;
      });

      this.item = result.items;
    },
    changeToArticle(value, recipe) {
      const selectedArticle = this.to.articlesForRecipes.find(article => article.id === parseInt(value));

      if (selectedArticle) { recipe.article = selectedArticle; }
    },
    completed() {
      if (!this.invalid) {
        // reset data fields
        Object.assign(this.$data, {

          edit: true,
          filter: {

            articleFrom: null,
            articleTo: null,
            days: [],
            ingredientFrom: null,
            ingredientTo: null,
            swapType: '',
            weeklyMenu: null,
          },
          item: [],
          searching: false,
        });

        this.$refs.filter.$v.$reset();
      }
    },
    submitData(item) {
      if (this.isMasterIngredient) {
        const params = this.masterSwapChanges.filter(recipe => !recipe.skipRecipeSwap).map(recipe => {
          return {
            from: recipe.from,
            to: recipe.to,
          };
        });
        return swapsMasterIngredient.savePatchMethod({ingredients_data: params});
      }
      const payload = item.filter(recipe => !recipe.skipRecipeSwap).map(recipe => {
        return {

          days: this.filter.days.map(day => day.id),
          people: Object.keys(recipe.to).reduce((accumulate, current) => {
            // if this people is skip don't include in object
            if (recipe.to[current].skipArticle) { return accumulate; }

            const value = recipe.to[current];

            accumulate[current] = {
              article: value.article.id,
              quantity: value.quantity,
            };

            return accumulate;
          }, {}),
          recipe: recipe.id,
          swapFrom: this.from.id,
          swapTo: this.to.id,
          type: this.filter.swapType,
          weekly_menu: this.filter.weeklyMenu.id,
        };
      });

      return swaps.saveOrUpdate(payload);
    },
    handleMasterSwapChanges(items) {
      this.masterSwapChanges = items.map(it => ({
        skipRecipeSwap: it.skipRecipeSwap,
        from: {
          main_ingredient_id: it.main_ingredient_id,
          portion_size: it.portion_size,
          people: it.people,
          quantity: it.quantity,
        },
        to: {
          main_ingredient_id: this.to.id,
          portion_size: it.to.portion_size,
          people: it.people,
          quantity: it.to.quantity,
          portion_size_uom_id: this.to.articlesForRecipes[0].portion_size_uom.id,
        },
      }));
    },
    canCreate() {
      switch (this.filter.swapType) {
      case 'master-ingredient':
        return this.can(Permissions.INGREDIENTS_SWAP_MASTER_RECIPES_UPDATE);
      case 'ingredient':
        return this.can(Permissions.INGREDIENTS_SWAP_UPDATE);
      case 'article':
        return this.can(Permissions.ARTICLES_CLONE_UPDATE);
      default:
        return false;
      }
    },
  },
};
</script>

<style scoped>
table.custom-table tbody td,
table.custom-table thead th {
  border-right: 1px solid #dee2e6;
}

table.custom-table tbody td:last-child,
table.custom-table thead th:last-child {
  border-right: 0;
  padding-right: 0.5rem;
}

table.custom-table th {
  font-weight: 600;
  color: #333;
}

.recipe-name-column {
  width: 22%;
}

table.custom-table tbody th {
  font-size: 0.7rem;
}

.card-to {
  background-color: #F2FFF6;
}

.big-chevron {
  font-size: 3rem;
}
</style>
