<template>
  <div>
    <b-modal
      v-model="isModalShown"
      :centered="true"
      class="modal-lg"
      size="lg"
      title="Recipes list"
      id="modal-lg">
      <template slot="modal-header-close"><wbr/></template>
      <dimmer :active="pending">
        <div v-if="recipesList.length">
          <div
            v-for="recipe in recipesList"
            :key="recipe.id"
          >
            <router-link
              v-if="can(uiPermissions.RECIPES_VIEW)"
              :to="`/recipes/${recipe.id}`"
              target="_blank"
              class="d-block mb-2">
              {{recipe.name}}
            </router-link>
            <template v-else>
              {{recipe.name}}
            </template>
          </div>
          <pagination
            :currentPage="links.current_page"
            :totalPages="links.last_page"
            @goToPage="(e) => customPage(e)"/>
        </div>
        <div v-else-if="!recipesList.length && !pending">
          No recipes was found
        </div>
      </dimmer>
    </b-modal>
    <table data-test="table-swap" class="table table-outline table-vcenter card-table">
      <thead>
        <tr>
          <th data-test="thead-count" colspan="2">Showing {{itemsList.length}} recipes to swap</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(recipe, index) in itemsList"
          :key="index"
          :class="{ 'table-active': recipe.skipRecipeSwap }">
          <td class="recipe-name-column">
            <div class="d-flex flex-column justify-content-between">
              <div
                class="recipes-btn"
                data-test="link-recipe"
                rel="noopener noreferrer"
                @click="getRecipesList(recipe)">
                {{recipe.totalRecipes}} recipe(s)
              </div>
              <div class="mt-4">
                <label data-test="label-skip-swap" class="form-check form-check-inline">
                  <input
                    v-model="recipe.skipRecipeSwap"
                    class="form-check-input"
                    type="checkbox"
                    @change="$emit('master-swap-changed', itemsList)">
                  <span class="form-check-label small">Skip swap for this recipe</span>
                </label>
              </div>
            </div>
          </td>
          <td v-if="recipe.skipRecipeSwap"></td>
          <td v-else>
            <div class="d-flex align-items-center">
              <div data-test="card-from" class="card bg-light p-2">
                <div class="card-header px-0 mx-2">
                  <div class="card-title">
                    <h4 class="mb-0">FROM:</h4>
                    <h3>{{nameFrom}}</h3>
                  </div>
                </div>
                <div class="card-body px-0">
                  <table class="table bg-transparent table-sm custom-table">
                    <thead>
                      <tr>
                        <th class="border-0"></th>
                        <th class="border-bottom-0 text-center">
                          {{recipe.people}} People
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(label, row) in ['Quantity', 'Portion']" :key="row">
                        <th class="pl-2">{{label}}</th>
                        <td class="text-center">
                          <input
                            v-if="!row"
                            :value="recipe.quantity"
                            type="text"
                            class="form-control"
                            disabled>
                          <input
                            v-else
                            :value="`${recipe.article_name}`"
                            type="text"
                            class="form-control"
                            disabled>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h1 class="fe fe-chevrons-right big-chevron"></h1>
              </div>
              <div data-test="card-to" class="card border-success card-to p-2">
                <div class="card-header px-0 mx-2">
                  <div class="card-title">
                    <h4 class="mb-0">TO:</h4>
                    <h3>{{to.name}}</h3>
                  </div>
                </div>
                <div class="card-body px-0">
                  <table class="table bg-transparent table-sm custom-table">
                    <thead>
                      <tr>
                        <th class="border-0"></th>
                        <th class="border-bottom-0 text-center">
                          {{recipe.people}} People
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(label, row) in ['Quantity', 'Portion']" :key="row">
                        <th class="pl-2">{{label}}</th>
                        <td class="text-center text-nowrap">
                          <select
                            v-if="!row"
                            v-model="recipe.to.quantity"
                            data-test="select-to-quantity"
                            class="form-control custom-select"
                            required
                            @change="$emit('master-swap-changed', itemsList)">
                            <option :value="null">Select</option>
                            <option
                              v-for="range in 10"
                              :key="range"
                              :value="range">
                              {{range}}
                            </option>
                          </select>
                          <select
                            v-else
                            v-model="recipe.to.portion_size"
                            data-test="select-to-portion"
                            class="form-control custom-select"
                            required
                            @change="$emit('master-swap-changed', itemsList)">
                            <option :value="null">Select</option>
                            <option
                              v-for="article in to.articlesForRecipes"
                              :key="article.id"
                              :value="article.portion_size">
                              {{article.name}}
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="!itemsList.length">
          <td class="text-center" colspan="2">
            No recipes to swap
            <div><button class="btn btn-sm btn-primary" @click.prevent="edit = true">Edit filter</button></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {recipesListForSwap} from '@/services';
import Pagination from '@/components/Pagination';

export default {
  name: 'MasterSwapTable',
  components: {
    Pagination,
  },
  props: {
    itemsList: {
      type: Array,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
    nameFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      edit: false,
      isModalShown: false,
      pending: false,
      recipesList: [],
      links: null,
      params: null,
    };
  },
  watch: {
    'itemsList.to': {
      handler() {
        this.$emit('master-swap-changed', this.itemsList);
      },
    },
  },
  methods: {
    async customPage(e) {
      this.links.page = e.page;
      await this.getRecipesList(this.params, this.links.page, true);
    },
    async getRecipesList(item, page = 1, fromPagination = false) {
      this.recipesList = [];
      this.pending = true;
      this.isModalShown = true;
      if (!fromPagination) {
        this.params = {
          mainIngredientId: item.main_ingredient_id,
          portionSize: item.portion_size,
          people: item.people,
          quantity: item.quantity,
        };
      }
      const {recipes, links} = await recipesListForSwap.getByParameters({
        ...this.params,
        page,
      });
      this.recipesList = recipes;
      this.pending = false;
      this.links = links;
    },
  },
};
</script>

<style scoped>
.recipes-btn {
  cursor: pointer;
  color: #467fcf;
}
</style>
