<template>
  <div>
    <template v-if="edit">
      <form
        data-test="form-swap-tool"
        :class="{ 'was-validated': $v.filter.$dirty }"
        @submit.prevent="validate">
        <div class="form-group row d-flex">
          <label
            data-test="label-swap-type"
            class="col-sm-3 col-form-label form-label text-right">
            Swap type
            <span
              class="text-danger">*</span></label>
          <div class="col-sm-7">
            <select
              v-model="$v.filter.swapType.$model"
              data-test="select-swap-type"
              :class="{ 'is-invalid': $v.filter.swapType.$error }"
              class="form-control custom-select">
              <option value="">Choose swap type</option>
              <option v-if="can(uiPermissions.INGREDIENTS_SWAP_MASTER_RECIPES_VIEW)" value="master-ingredient">Master Ingredient swap</option>
              <option v-if="can(uiPermissions.INGREDIENTS_SWAP_VIEW)" value="ingredient">Ingredient swap</option>
              <option v-if="can(uiPermissions.ARTICLES_CLONE_VIEW)" value="article">Article swap</option>
            </select>
            <template v-if="$v.filter.swapType.$dirty">
              <div v-if="!$v.filter.swapType.required" class="invalid-feedback d-block">Swap type is required</div>
            </template>
          </div>
        </div>
        <template v-if="!isMasterIngredient">
          <div class="form-group row d-flex">
            <label data-test="label-weekly-menu" class="col-sm-3 col-form-label form-label text-right">Weekly Menu <span
              class="text-danger">*</span></label>
            <div class="col-sm-7">
              <autocomplete
                v-model="$v.filter.weeklyMenu.$model"
                :list.sync="weeklyMenus"
                :class="{ 'is-invalid': $v.filter.weeklyMenu.$error }"
                :nextPage="nextWeeklyMenus"
                data-test="div-weekly-menu"
                label="weekName"
                entity="weekly menu"></autocomplete>
              <template v-if="$v.filter.weeklyMenu.$dirty">
                <div v-if="!$v.filter.weeklyMenu.required" class="invalid-feedback d-block">Weekly menu is required</div>
              </template>
            </div>
          </div>
          <div class="form-group row d-flex">
            <label data-test="label-days" class="col-sm-3 form-label text-right">Days <span class="text-danger">*</span></label>
            <div class="col-sm-7">
              <label
                v-for="date in deliveryDays"
                :key="date.id"
                class="form-check form-check-inline">
                <input
                  :checked="!!$v.filter.days.$model.find(item => item.id === date.id)"
                  data-test="input-days"
                  class="form-check-input"
                  type="checkbox"
                  @change="toggleArray($v.filter.days.$model, date, (array, selected) => array.id === selected.id)">
                <span class="form-check-label">{{date.name}}</span>
              </label>
              <template v-if="$v.filter.days.$dirty">
                <div v-if="!$v.filter.days.required" class="invalid-feedback d-block">Days is required</div>
              </template>
            </div>
          </div>
        </template>
        <div v-if="showIngredients">
          <div data-test="form-ingredient" class="form-group row d-flex">
            <label
              data-test="label-ingredient"
              class="col-sm-3 text-right"
              :class="isIngredient ? 'form-label' : 'col-form-label form-label'">Ingredient: <span
                class="text-danger">*</span></label>
            <div class="col-sm-7">
              <div v-if="isIngredient || isMasterIngredient" class="row">
                <div class="col-sm-6">
                  <label data-test="label-from" class="form-label">From</label>
                  <autocomplete
                    v-model="$v.filter.ingredientFrom.$model"
                    :class="{ 'is-invalid': $v.filter.ingredientFrom.$error }"
                    :list.sync="ingredientsFrom"
                    :nextPage="nextMainIngredientsFrom"
                    data-test="input-from"
                    label="name"
                    entity="ingredient"></autocomplete>
                  <template v-if="$v.filter.ingredientFrom.$dirty">
                    <div
                      v-if="!$v.filter.ingredientFrom.required && (isIngredient || isMasterIngredient)"
                      class="invalid-feedback d-block">
                      Ingredient from is required
                    </div>
                  </template>
                </div>
                <div class="col-sm-6">
                  <label data-test="label-to" class="form-label">To</label>
                  <autocomplete
                    v-model="$v.filter.ingredientTo.$model"
                    :class="{ 'is-invalid': $v.filter.ingredientTo.$error }"
                    :list.sync="ingredientsTo"
                    :nextPage="nextMainIngredientsTo"
                    label="name"
                    data-test="input-to"
                    entity="ingredient"></autocomplete>
                  <template v-if="$v.filter.ingredientTo.$dirty">
                    <div v-if="!$v.filter.ingredientTo.required" class="invalid-feedback d-block">
                      Ingredient to is
                      required
                    </div>
                    <div v-if="!$v.filter.ingredientTo.mustBeDifferent" class="invalid-feedback d-block">
                      Ingredient must
                      be different from "ingredient from"
                    </div>
                  </template>
                </div>
              </div>
              <template v-else>
                <autocomplete
                  :value="$v.filter.ingredientFrom.$model"
                  :class="{ 'is-invalid': $v.filter.ingredientFrom.$error }"
                  :list.sync="ingredientsFrom"
                  :nextPage="nextMainIngredientsFrom"
                  label="name"
                  entity="ingredient"
                  @input="newValue => ingredientFromArticles(newValue)"></autocomplete>
                <template v-if="$v.filter.ingredientFrom.$dirty">
                  <div
                    v-if="!$v.filter.ingredientFrom.required && (!isIngredient || !isMasterIngredient)"
                    class="invalid-feedback d-block">
                    Ingredient is required
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div v-if="!isIngredient && !isMasterIngredient" class="form-group row d-flex">
            <label data-test="label-article" class="col-sm-3 form-label text-right">Article <span class="text-danger">*</span></label>
            <div class="col-sm-7">
              <div class="row">
                <div class="col-sm-6">
                  <label data-test="label-from" class="form-label">From</label>
                  <select
                    v-model="$v.filter.articleFrom.$model"
                    :class="{ 'is-invalid': $v.filter.articleFrom.$error }"
                    data-test="select-from"
                    class="form-control custom-select">
                    <option :value="null">Choose an article</option>
                    <option
                      v-for="article in articlesFrom"
                      :key="article.id"
                      :value="article"
                      :alt="article.name">
                      {{article.name}}
                    </option>
                  </select>
                  <template v-if="$v.filter.articleFrom.$dirty">
                    <div v-if="!$v.filter.articleFrom.required" class="invalid-feedback d-block">
                      Article from is required
                    </div>
                  </template>
                </div>
                <div class="col-sm-6">
                  <label data-test="label-to" class="form-label">To</label>
                  <select
                    v-model="$v.filter.articleTo.$model"
                    :class="{ 'is-invalid': $v.filter.articleTo.$error }"
                    data-test="select-to"
                    class="form-control custom-select">
                    <option :value="null">Choose an article</option>
                    <option
                      v-for="article in articles"
                      :key="article.id"
                      :value="article"
                      :alt="article.name">
                      {{article.name}}
                    </option>
                  </select>
                  <template v-if="$v.filter.articleTo.$dirty">
                    <div v-if="!$v.filter.articleTo.required" class="invalid-feedback d-block">
                      Article to is required
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto">
            <button data-test="btn-next" class="form-control btn btn-primary">Next</button>
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <form-description label="Swap type" :value="filter.swapType"/>
      <form-description
        v-if="filter.weeklyMenu"
        label="Weekly menu"
        :value="filter.weeklyMenu.weekName"/>
      <form-description
        v-if="filter.days.length"
        label="Days to swap"
        :value="filter.days.map(day => day.name).join(', ')"/>
      <form-description
        v-if="isIngredient || isMasterIngredient"
        label="Swap ingredient from"
        :value="(filter.ingredientFrom || {}).name"/>
      <form-description
        v-if="isIngredient || isMasterIngredient"
        label="Swap ingredient to"
        :value="(filter.ingredientTo || {}).name"/>
      <form-description
        v-if="!isIngredient && !isMasterIngredient"
        label="Ingredient"
        :value="(filter.ingredientFrom || {}).name"/>
      <form-description
        v-if="!isIngredient && !isMasterIngredient"
        label="Swap article from"
        :value="(filter.articleFrom || {}).name"/>
      <form-description
        v-if="!isIngredient && !isMasterIngredient"
        label="Swap article to"
        :value="(filter.articleTo || {}).name"/>
    </template>
  </div>
</template>

<script>

import Autocomplete from '@/components/Autocomplete';
import FormDescription from '@/components/FormDescription';
import {deliveryDays, mainIngredients, swappableClones, weeklyMenusBasic} from '@/services';
import {weekName} from '@hellochef/shared-js/helpers';
import {helpers, required, requiredIf} from 'vuelidate/lib/validators';

export default {
  components: {
    Autocomplete,
    FormDescription,
  },
  props: {
    edit: {
      default: true,
      required: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      articlesFrom: [],
      deliveryDays: [],
      ingredientsFrom: [],
      ingredientsTo: [],
      weeklyMenus: [],
    };
  },
  computed: {
    articles() {
      return this.filter.ingredientFrom?.articlesForRecipes || [];
    },
    isIngredient() {
      return this.filter.swapType === 'ingredient';
    },
    isMasterIngredient() {
      return this.filter.swapType === 'master-ingredient';
    },
    showIngredients() {
      return this.isMasterIngredient ? this.filter.swapType : this.filter.swapType && this.filter.weeklyMenu && this.filter.days.length;
    },
  },
  watch: {
    'filter.swapType': {
      handler() {
        if (this.isMasterIngredient) {
          this.filter.days = [];
          this.filter.weeklyMenu = null;
        }
      },
    },
  },
  created() {
    deliveryDays.getByParameters({column: 'id', direction: 'asc'}).then(result => this.deliveryDays = result.items);
    mainIngredients.getByParameters({column: 'name', direction: 'asc', with: 'articlesForRecipes'}).then(result => {
      this.ingredientsFrom = result;
    });

    mainIngredients.getByParameters({column: 'name', direction: 'asc', with: 'articlesForRecipes', is_active: 1}).then(result => {
      this.ingredientsTo = result;
    });

    weeklyMenusBasic.getByParameters({column: 'id', direction: 'desc', status: ['published']}).then(result => {
      Object.assign(result, {

        items: result.items.map(item => {
          return Object.assign(item, {weekName: weekName(item.startDate)});
        }),
      });

      this.weeklyMenus = result;
    });
  },
  methods: {
    async nextMainIngredientsFrom(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        page,
        with: 'articlesForRecipes',
      };

      if (query.length) params.query = query;

      const result = await mainIngredients.getByParameters(params);

      return result;
    },
    async nextMainIngredientsTo(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        page,
        with: 'articlesForRecipes',
        is_active: 1,
      };

      if (query.length) params.query = query;

      const result = await mainIngredients.getByParameters(params);

      return result;
    },
    async nextWeeklyMenus(page, query) {
      const params = {

        column: 'id',
        direction: 'desc',
        page,
        status: ['published'],
      };

      if (query.length) params.query = query;

      const result = await weeklyMenusBasic.getByParameters(params);

      Object.assign(result, {

        items: result.items.map(item => {
          return Object.assign(item, {weekName: weekName(item.startDate)});
        }),
      });

      return result;
    },
    async ingredientFromArticles(newValue) {
      // reset articles dropdown values
      this.filter.articleFrom = null;
      this.filter.articleTo = null;

      this.filter.ingredientFrom = newValue;

      const params = {
        delivery_days: this.filter.days.map(day => day.id),
        weekly_menu_id: this.filter.weeklyMenu.id,
        ingredient_id: this.filter.ingredientFrom.id,
      };

      const result = await swappableClones.getByParameters(params);
      this.articlesFrom = result.articles || [];
    },
    validate() {
      this.$v.filter.$touch();

      if (!this.$v.filter.$invalid) {
        this.$emit('valid', this.filter);
      }
    },
  },
  validations: {
    filter: {
      articleFrom: {
        required: requiredIf(function(value) {
          return !this.isIngredient && !this.isMasterIngredient;
        }),
      },
      articleTo: {
        required: requiredIf(function(value) {
          return !this.isIngredient && !this.isMasterIngredient;
        }),
      },
      days: {
        required: requiredIf(function(value) {
          return !this.isMasterIngredient ? value.length : false;
        }),
      },
      ingredientFrom: {
        required,
      },
      ingredientTo: {

        mustBeDifferent: function(value) {
          if (!this.isMasterIngredient) {
            return !helpers.req(value) || (!(this.filter.ingredientFrom?.id && this.filter.ingredientFrom.id === value.id));
          }
          return true;
        },
        required: requiredIf(function(value) {
          return this.isIngredient || this.isMasterIngredient;
        }),
      },
      swapType: {
        required,
      },
      weeklyMenu: {
        required: requiredIf(function(value) {
          return !this.isMasterIngredient;
        }),
      },
    },
  },
};
</script>
